'use strict';

window.console || (window.console = {
	log: function log() {}
});
var SLSD = window.SLSD || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	SLSD = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (SLSD.va.window.width <= SLSD.va.device.desktop) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		headerMenu: function headerMenu() {
			var $header = $('.header');
			var $menuBtn = $('.header-menuBtn');
			var $headerMenu = $('.header-menuBlock');
			var $headerLang = $('select[name="header-lang"]');
			// const s = 'is-small';

			// スクロールしたらヘッダー縮小
			// $(window).scroll((e) => {
			// 	let $this = $(e.currentTarget);
			// 	let thisScroll = $this.scrollTop();
			// 	if(thisScroll > 120) {
			// 		$header.addClass(s);
			// 	} else {
			// 		$header.removeClass(s);
			// 	}
			// });
			// if($(window).scrollTop() > 120) {
			// 	$header.addClass(s);
			// } else {
			// 	$header.removeClass(s);
			// }

			// menu開閉
			$menuBtn.on('click', function (e) {
				var $this = $(e.currentTarget);
				$this.toggleClass(a);
				$headerMenu.toggleClass(a);
			});

			$headerLang.on('change', function (e) {
				$(e.currentTarget).children('option').each(function (idx, ele) {
					if ($(ele).prop('selected') === true) {
						if ($(ele).val() === 'ENGLISH') {
							location.href = '/en/';
						} else {
							location.href = '/';
						}
					}
				});
			});
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pageTop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $footer.height() + thisScroll;
				if (SLSD.va.window.width <= 1024) {
					pagetopPos += 70;
				}

				if (thisScroll > '100') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}

				if (footerPos <= pagetopPos) {
					$pagetop.addClass('is-bottom');
				} else {
					$pagetop.removeClass('is-bottom');
				}

				if (location.pathname === '/' || location.pathname === '/index.html') {
					$(window).on('resize', function () {
						var blockH = $('.idx-bottomBnr').height();
						pagetopPos += blockH;

						if (footerPos <= pagetopPos) {
							$pagetop.addClass('is-bottom');
							var thisTopPos = void 0;
							if (SLSD.va.window.width <= 1024) {
								thisTopPos = blockH + 40;
							} else {
								thisTopPos = blockH + 80;
							}
							$('.footer-pageTop').css('top', -thisTopPos + 'px');
						} else {
							$pagetop.removeClass('is-bottom');
							$('.footer-pageTop').css('top', 'auto');
						}
					}).resize();
				}
			});
		},
		navCurrent: function navCurrent() {
			var $navItem = $('.js-nav-current');
			var $map = SLSD.va.pathname;
			if ($navItem.length) {
				$navItem.each(function (idx, ele) {
					if ($map === $(ele).attr('href')) {
						$(ele).addClass(a);
					}
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.headerMenu();
			_this.pageTop();
			_this.navCurrent();
		}
	};

	$(function () {
		return SLSD.localDecision() ? SLSD.localLoading() : SLSD.loadDelayScript();
	});
})(window.jQuery);